import { Link } from 'react-scroll';
import AppLayout from '../../../layouts/AppLayout';
import Section from '../../../components/Section';
import Awards from '../../../components/Awards';
import Blog from '../../../../blog/components/Blog';
import Contact from '../../../components/Contact';
import Steps from '../components/Steps';
import styles from './ContinuousAuthentication.module.scss';

import timer from '../components/gifs/timer.gif';
import ux from '../components/gifs/ux.gif';
import cloud from '../components/gifs/cloud.gif';
import hardware from '../components/gifs/hardware.gif';
import geolocation from '../components/gifs/geolocation.gif';
import network from '../components/gifs/network.gif';
import behavior from '../components/gifs/behavior.gif';
import user from '../components/gifs/user.gif';
import software from '../components/gifs/software.gif';

import chart from '../components/icons/chart36.svg';
import coins from '../components/icons/coins12.svg';
import graph from '../components/icons/graph230.svg';

const ContinuousAuthentication = () => {
  return (
    <AppLayout>
      <div className={styles.ContinuousAuthentication}>
        <div className={styles.GradientBg}>
          <Section
            title="Continuous Authentication"
            className={styles.Hero}
          >
            <div className="container">
              <div className="row mt-lg-3 mb-lg-5">
                <div className="col-12 text-center">
                  <div className={styles.hexaContainer}>
                    <div className={styles.Hexagon}>
                      <div className={styles.hexaBorder}>
                        <div className={styles.hexaBg}>
                          <div className={styles.hexaShadow}>
                            <div className={styles.hexaSelector}>
                              <h3>Typing Biometrics</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.Hexagon}>
                      <div className={styles.hexaBorder}>
                        <div className={styles.hexaBg}>
                          <div className={styles.hexaShadow}>
                            <div className={styles.hexaSelector}>
                              <h3>Cursor Movement Biometrics</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.Hexagon}>
                      <div className={styles.hexaBorder}>
                        <div className={styles.hexaBg}>
                          <div className={styles.hexaShadow}>
                            <div className={styles.hexaSelector}>
                              <h3>Device Fingerprinting</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3 mb-5">
                <div className="col-12 text-center">
                  <h3>
                    Prevent account takeover frauds on desktop, web, and mobile.
                    <br />
                    Ensure Zero Trust workforce authentication and privileged
                    access management.
                    <br />
                    Prevent unauthorized account and device sharing.
                  </h3>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-4 mb-3">
                  <div className={styles.Box}>
                    <div className={styles.BoxImage}>
                      <img src={cloud} width={100} alt="cloud" />
                    </div>
                    <h4>
                      Workforce Authentication
                    </h4>
                    <p>
                      Advanced AI analyzes user behavior and identifies
                      potential scams
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 mb-3">
                  <div className={styles.Box}>
                    <div className={styles.BoxImage}>
                      <img src={ux} width={100} alt="ux" />
                    </div>
                    <h4>
                      Web User Authentication
                    </h4>
                    <p>
                      Enhances security
                      <br />
                      without compromising the user experience
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 mb-3">
                  <div className={styles.Box}>
                    <div className={styles.BoxImage}>
                      <img src={timer} width={100} alt="timer" />
                    </div>
                    <h4>
                      Silent 2FA
                    </h4>
                    <p>
                      Approve logins and transactions invisibly and prevent
                      website spoofing or mirror phishing fraud
                    </p>
                  </div>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-12 py-3 text-center">
                  <Link to="Contact" className="btn" spy smooth>
                    Book a Demo
                  </Link>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12 pt-3 text-center">
                  <h4>
                    Graboxy Continuous Authentication is part of the Graboxy AI
                    Cybersecurity Platform.
                  </h4>
                  <h4>
                    Learn more about the other features such as
                    {' '}
                    <br />
                    <a
                      href="/solutions/remote-desktop-detection"
                      className="mainlink"
                    >
                      Remote Desktop Detection
                    </a>
                    ,
                    {' '}
                    <a
                      href="/solutions/multi-factor-authentication"
                      className="mainlink"
                    >
                      Silent 2FA
                    </a>
                    ,
                    {' '}
                    <a
                      href="/solutions/device-fingerprinting"
                      className="mainlink"
                    >
                      Device Fingerprinting
                    </a>
                    , and
                    {' '}
                    <a
                      href="/solutions/captcha-bot-detection"
                      className="mainlink"
                    >
                      CAPTCHA & Bot Detection.
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </Section>
        </div>
        <div className={styles.Awards}>
          <Awards />
        </div>
        <div className={styles.GradientBg}>
          <Section
            title="Full-Scale Continuous Authentication
              for Complete User Account Protection"
            className={styles.Defend}
          >
            <div className="container mt-4">
              <div className="row">
                <div className="col-lg-6">
                  <p>
                    Graboxy offers robust protection against unauthorized
                    access by integrating three different modules.
                  </p>
                  <p>
                    <strong>
                      Typing and Cursor Movement biometrics:
                    </strong>
                    {' '}
                    Graboxy monitors
                    how users type and move their cursor in real-time, creating
                    unique biometric profiles based on these behaviors.
                  </p>
                  <p>
                    <strong>
                      Device Fingerprinting:
                    </strong>
                    {' '}
                    To identify and validate devices.
                  </p>
                  <p>
                    These modules can operate independently or be combined with
                    existing security systems.
                  </p>
                  <p>
                    Graboxy evaluates user behavior against biometric profiles.
                    If a user's Identity Score drops below a set threshold, it
                    flags the session as suspicious and issues an
                    "Unauthorized User" alert. Suspicious user sessions can be
                    locked out or require additional multi-factor
                    authentication to regain access.
                  </p>
                </div>
                <div className="col-lg-6 d-flex">
                  <iframe
                    width="720"
                    height="365"
                    title="Graboxy"
                    src="https://player.vimeo.com/video/757605026?h=943777d9ab"
                    frameborder="0"
                    allow="autoplay;"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </Section>
        </div>
        <Section
          title="Case Study"
          className={styles.CaseStudy}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <div>
                  <h3 className="mb-4">
                    Continuous Online User Authentication in Banking
                  </h3>
                  <p className="mb-4">
                    Goal: Decrease the number of fraudulent online banking
                    transactions and reduce the cost of transaction
                    authorization
                  </p>
                  <div className={styles.Results}>
                    <div className="d-md-flex justify-content-center my-5">
                      <div className={styles.ResultItem}>
                        <p>
                          Cost saving on
                          <br />
                          OTPs via SMS:
                        </p>
                        <h3>-85%</h3>
                      </div>
                      <div className={styles.ResultItem}>
                        <p>Fraud detection accuracy*:</p>
                        <h3>97%</h3>
                        <span>
                          * Where sufficient training data was available.
                        </span>
                      </div>
                      <div className={styles.ResultItem}>
                        <p>Authenticated user sessions daily:</p>
                        <h3>100K+</h3>
                      </div>
                    </div>
                  </div>
                  <a href="/case-study" className="btn">
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section
          title="How Our Biometric Authentication System Works"
          className={styles.HowItWorks}
        >
          <div className="container">
            <Steps />
          </div>
        </Section>
        <Section
          title="How Device Fingerprinting Works"
          className={styles.Works}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <p>
                  Device fingerprinting is used to identify and track devices
                  based on their unique characteristics. Unlike traditional
                  methods like cookies, which can be deleted or blocked by
                  users, device fingerprinting gathers a variety of data points
                  from the device and its environment, creating a unique
                  "fingerprint" that is hard to alter.
                </p>
              </div>
            </div>
            <div className="row text-center mt-5">
              <div className="col-lg-4 mb-4">
                <div className={styles.Box}>
                  <img
                    src={behavior}
                    width={100}
                    className="mb-3"
                    alt="behavior"
                  />
                  <h4>Behavioral Patterns</h4>
                  <p>
                    Patterns of user behavior, such as  session duration,
                    and interaction with the service
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mb-4">
                <div className={styles.Box}>
                  <img
                    src={geolocation}
                    width={100}
                    className="mb-3"
                    alt="geolocation"
                  />
                  <h4>Geolocation</h4>
                  <p>
                    The physical location of the device based on its IP
                    address or GPS coordinates
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mb-4">
                <div className={styles.Box}>
                  <img
                    src={network}
                    width={100}
                    className="mb-3"
                    alt="network"
                  />
                  <h4>Network Information</h4>
                  <p>
                    Data related to the device's IP address, network type,
                    and connection speed
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mb-4">
                <div className={styles.Box}>
                  <img
                    src={hardware}
                    width={100}
                    className="mb-3"
                    alt="hardware"
                  />
                  <h4>Hardware Configuration</h4>
                  <p>
                    Information about the device's hardware components,
                    such as its processor, graphics card, and memory
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mb-4">
                <div className={styles.Box}>
                  <img src={user} width={100} className="mb-3" alt="user" />
                  <h4>User Agent</h4>
                  <p>
                    Information sent by the device's browser or application,
                    which includes details like the device's user agent
                    string, screen resolution, and language settings
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mb-4">
                <div className={styles.Box}>
                  <img
                    src={software}
                    width={100}
                    className="mb-3"
                    alt="software"
                  />
                  <h4>Software Settings</h4>
                  <p>
                    Details about the device's operating system, browser
                    version, installed plugins or extensions, and other
                    software configurations
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section
          title="Did You Know?"
          className={styles.DidYouKnow}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-4 mb-4 mb-lg-0">
                <div className={styles.Box}>
                  <img src={coins} className="mb-4" height={75} alt="coins" />
                  <p>
                    The average loss of an account takeover fraud is around
                    $12,000 per case.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0">
                <div className={styles.Box}>
                  <img src={chart} className="mb-4" height={75} alt="chart" />
                  <p>
                    36% of businesses have experienced a security incident
                    caused by the actions of remote workers.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mb-4 mb-lg-0">
                <div className={styles.Box}>
                  <img src={graph} className="mb-4" height={75} alt="graph" />
                  <p>
                    In the most recent annual report, there has been a rise of
                    over 230% in remote access scams compared to the prior
                    year.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <div className={styles.Blog}>
          <Blog />
        </div>
        <div className={styles.Contact}>
          <Contact />
        </div>
      </div>
    </AppLayout>
  );
};

export default ContinuousAuthentication;
