import React, { useState } from "react";
import { Link } from 'react-scroll';
import { Card, Col, Row, Fade } from "react-bootstrap";
import Section from '../Section';
import './ZeroTrust.scss';

import frictionless from './gifs/frictionless.gif';
import gui from './gifs/gui.gif';
import maze from './gifs/maze.gif';

import play from './play.svg';

const ZeroTrust = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  const handleTabClick = (index) => {
    setFadeIn(false);
    setTimeout(() => {
      setActiveTab(index);
      setFadeIn(true);
    }, 300);
  };

  return (
    <Section id="ZeroTrust" title="Combine the Layers to Fit Your Goals">
      <div className="container mt-3 mb-5">
        <Row>
          <Col md={3} className="mb-2 mb-md-0">
            <Card
              className={`content-tab mb-2 ${activeTab === 0 ? "bg-active text-white" : "bg-inactive text-white"}`}
              onClick={() => handleTabClick(0)}
              style={{ cursor: "pointer" }}
            >
              <Card.Body>Continuous Authentication</Card.Body>
            </Card>
            <Card
              className={`content-tab mb-2 ${activeTab === 1 ? "bg-active text-white" : "bg-inactive text-white"}`}
              onClick={() => handleTabClick(1)}
              style={{ cursor: "pointer" }}
            >
              <Card.Body>Remote Desktop Detection</Card.Body>
            </Card>
            <Card
              className={`content-tab ${activeTab === 2 ? "bg-active text-white" : "bg-inactive text-white"}`}
              onClick={() => handleTabClick(2)}
              style={{ cursor: "pointer" }}
            >
              <Card.Body>Captcha and Bot Detection</Card.Body>
            </Card>
          </Col>
          <Col md={9}>
            <Fade in={fadeIn}>
              <div>
                {activeTab === 0 && (
                  <Card>
                    <Card.Body>
                      <h3>
                        Prevent account takeovers on desktop, web & mobile
                      </h3>
                      <p>
                        Our continuous authentication solution has 3 different
                        modules that can work separately and can be easily
                        combined with existing security systems.
                      </p>
                      <p>
                        Graboxy can monitor how users type and move their
                        cursor in real-time, creating unique biometric profiles
                        based on these behaviors. It can also identifiy and
                        validate devices through advanced device fingerprinting
                        techniques, ensuring that only trusted devices can
                        access your systems.
                      </p>
                      <a
                        href="/solutions/continuous-authentication"
                        className="btn mb-3"
                      >
                        Learn More
                      </a>
                      <div className="hexa-container">
                        <div className="hexagon">
                          <div className="hexa-border">
                            <div className="hexa-bg">
                              <div className="hexa-shadow">
                                <div className="hexa-selector">
                                  <h3>Typing Biometrics</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="hexagon">
                          <div className="hexa-border">
                            <div className="hexa-bg">
                              <div className="hexa-shadow">
                                <div className="hexa-selector">
                                  <h3>Cursor Movement Biometrics</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="hexagon">
                          <div className="hexa-border">
                            <div className="hexa-bg">
                              <div className="hexa-shadow">
                                <div className="hexa-selector">
                                  <h3>Device Fingerprinting</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                )}
                {activeTab === 1 && (
                  <Card>
                    <Card.Body>
                      <h3>
                        Stop tech support, AnyDesk or other remote access scams
                        <br/>
                        within 5 seconds
                      </h3>
                      <table className="table table-hover">
                        <thead className="text-white">
                          <tr>
                            <th>Analyzed data</th>
                            <th>Time to value</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Pre-defined Behavior Patterns</td>
                            <td>Immediate</td>
                            <td>
                              <a
                                href="/solutions/device-fingerprinting"
                                className="mainlink"
                              >
                                Learn more
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="d-md-flex">
                        <div className="w-50">
                          <p>
                            Some glitches in remote desktop sessions go
                            unnoticed by human observers, but they can be
                            swiftly detected by our Remote Access Detection
                            solution.
                          </p>
                        </div>
                        <div className="w-50">
                          <iframe
                            title="Graboxy"
                            src="https://player.vimeo.com/video/952305360?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                            allow="autoplay; fullscreen; picture-in-picture"
                            className="video"
                            allowFullScreen
                          />
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                )}
                {activeTab === 2 && (
                  <Card>
                    <Card.Body>
                      <h3>
                      Frictionless detection of bots and scraping on your
                      website
                      </h3>
                      <table className="table table-hover">
                        <thead className="text-white">
                          <tr>
                            <th>Analyzed data</th>
                            <th>Time to value</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Human vs Bot Interactions</td>
                            <td>Immediate</td>
                            <td>
                              <a
                                href="/solutions/device-fingerprinting"
                                className="mainlink"
                              >
                                Learn more
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="row mt-4">
                        <div className="col-12 text-center mb-4">
                          <h3>Choose your CAPTCHA & Bot Detection Solutions</h3>
                        </div>
                        <div className="col-lg-4 text-center mb-4 mb-lg-0">
                          <div className="box">
                            <img
                              src={frictionless}
                              alt="gif"
                              height={80}
                              className="mb-4"
                            />
                            <h3>Frictionless Detection</h3>
                            <p>
                              Based on device fingerprinting,
                              and movement & behavior analysis
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4 text-center mb-4 mb-lg-0">
                          <div className="box">
                            <img
                              src={gui}
                              alt="gif"
                              height={80}
                              className="mb-4"
                            />
                            <h3>Embeddable<br/>GUI Element</h3>
                            <p>
                              Such as tick boxes, which are very
                              easy and quick to solve for humans
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4 text-center mb-4 mb-lg-0">
                          <div className="box">
                            <img
                              src={maze}
                              alt="gif"
                              height={80}
                              className="mb-4"
                            />
                            <h3>Brandable<br/>Maze Captcha</h3>
                            <p>
                              Provides challenge-response
                              capability when critical security is needed
                            </p>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                )}
              </div>
            </Fade>
          </Col>
        </Row>
        <div className="text-center mt-5">
          <Link
            to="Contact"
            className="btn"
            spy
            smooth
          >
            Book a Demo
            {' '}
            <img src={play} alt="play" />
          </Link>
        </div>
      </div>
    </Section>
  )
};

export default ZeroTrust;
